import { ChartDataTypeType } from "@src/hooks/ab-testing/use-chart-layout";

export const GeneralSampleSizeName = "Original sample size";
export const GeneralSampleSizeTooltip = "Sample size from general info";
export const ExtraSampleSizeName = "Selected users";
export const ExtraSampleSizeTooltip = "Sample size after filtering the dimensions configured in Extra filter";
export const InvalidSampleSizeName = "Valid users";
export const InvalidSampleSizeTooltip = "Valid sample size after logics removing users of Abalyzer";

export class UserSampleSizeHelper {
  private chartDataType: ChartDataTypeType | undefined;
  private generalSampleSize: number | undefined;
  private extraSampleSize: number | undefined;
  private invalidSampleSize: number | undefined;

  setChartDataType(chartDataType: ChartDataTypeType) {
    this.chartDataType = chartDataType;
  }

  setGeneralSampleSize(generalSampleSize: number) {
    this.generalSampleSize = generalSampleSize;
  }

  setExtraSampleSize(extraSampleSize: number) {
    this.extraSampleSize = extraSampleSize;
  }

  setInvalidSampleSize(invalidSampleSize: number) {
    this.invalidSampleSize = invalidSampleSize;
  }

  getDataSourceForPreviousStep() {
    if (
      this.generalSampleSize === undefined ||
      this.extraSampleSize === undefined ||
      this.invalidSampleSize === undefined
    )
      return [];
    const result = [];
    if (this.generalSampleSize !== 0) {
      result.push({
        name: GeneralSampleSizeName,
        value: this.generalSampleSize,
        userCount: this.generalSampleSize,
        userPercent: 100,
      });
    }
    if (this.extraSampleSize !== 0 && this.generalSampleSize !== 0) {
      const percent = Number(((this.extraSampleSize / this.generalSampleSize) * 100).toFixed(2));
      result.push({
        name: ExtraSampleSizeName,
        value: this.extraSampleSize,
        userCount: this.extraSampleSize,
        userPercent: percent,
      });
    }
    if (this.invalidSampleSize !== 0 && this.extraSampleSize !== 0) {
      const percent = Number(((this.invalidSampleSize / this.generalSampleSize) * 100).toFixed(2));
      result.push({
        name: InvalidSampleSizeName,
        value: this.invalidSampleSize,
        userCount: this.invalidSampleSize,
        userPercent: percent,
      });
    }
    return result;
  }
  // getDataSourceForFirstStep() {
  //   if (
  //     this.generalSampleSize === undefined ||
  //     this.extraSampleSize === undefined ||
  //     this.invalidSampleSize === undefined
  //   )
  //     return [];
  //   const result = [];
  //   if (this.generalSampleSize !== 0) {
  //     result.push({
  //       name: this.combineNameCorrespondingChartType(GeneralSampleSizeName, {
  //         count: this.generalSampleSize,
  //         ratio: 100,
  //       }),
  //       value: 100,
  //     });
  //   }
  //   if (this.extraSampleSize !== 0 && this.generalSampleSize !== 0) {
  //     const percent = (this.extraSampleSize / this.generalSampleSize) * 100;
  //     result.push({
  //       name: this.combineNameCorrespondingChartType(ExtraSampleSizeName, {
  //         count: this.extraSampleSize,
  //         ratio: percent,
  //       }),
  //       value: percent,
  //     });
  //   }
  //   if (this.invalidSampleSize !== 0 && this.generalSampleSize !== 0) {
  //     const percent = (this.invalidSampleSize / this.generalSampleSize) * 100;
  //     result.push({
  //       name: this.combineNameCorrespondingChartType(InvalidSampleSizeName, {
  //         count: this.invalidSampleSize,
  //         ratio: percent,
  //       }),
  //       value: percent,
  //     });
  //   }
  //   return result;
  // }
}
